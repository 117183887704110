<template>
  
     <section class="affordability-result-page">
                <div class="container-fluid">
                    <div class="affordability-result-top-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="affordability-result-card">
                                    <div class="maximum-loan-amount-card w30">
                                        <h4>Your Maximum Loanable Amount</h4>
                                        <h2>₦ {{currencyFormat(resultData.max_loan_amount)}}.<small>00</small></h2>
                                    </div>
                                    <div class="monthly-repayment-card w30">
                                        <h4>Estimated Monthly Repayment</h4>
                                        <h2>₦ {{currencyFormat(resultData.monthly_payment)}}.<small>00</small></h2>
                                    </div>
                                    <div class="maximum-tenure-card w30">
                                        <h4>Maximum Tenure</h4>
                                        <h2>{{resultData.loan_tenure}}  Years</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
</template>
<script>
import {FormMixin} from '@/mixins/form'
export default {
    name:"Result",
    mixins:[FormMixin],
    computed:{
        resultData(){
            let result = this.$store.state.calculator_module.form;
            return result;
        }
    }
}
</script>
<style scoped >
/*  */
@media (max-width:1000px){
    /* .affordability-result-card{
        flex-direction: column;
        padding:1rem 0;
    } 
    .w30{
        width:100%;
    }
    .maximum-loan-amount-card,.monthly-repayment-card{
        padding-bottom: 0.3rem;
         border-bottom: 1px solid #ffffff;
    }
    .maximum-loan-amount-card::after ,.monthly-repayment-card::after{
   
    border-right: none;
    }
    .maximum-loan-amount-card h2, .monthly-repayment-card h2,.maximum-tenure-card h2{
        font-size:24px;
    }
    .maximum-loan-amount-card h4, .monthly-repayment-card h4,.maximum-tenure-card h4{
        font-size:10px;
    } */
}
</style>